<template>
  <div class="widgetContainer widgetContainer--scrollable businessDetail">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          Business Profile
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>
    
    <div class="widgetContainer__body">
      <p class="header-2">
        Business Profile
      </p>
      <div class="listWithInfo border-1">
        <ul>
          <li>
            <p class="label">
              {{ $t('profile_legalname') }}
            </p>
            <p class="value">
              {{ getSelectedBusiness.legalName }}
            </p>
          </li>
          <li>
            <p class="label">
              {{ $t('profile_dba') }}
            </p>
            <p class="value">
              {{ getSelectedBusiness.dba }}
            </p>
          </li>
        </ul>
      </div>

      <p class="wise-form__header">
        {{ $t('profile_account_header') }}
      </p>
      <div class="listWithInfov2 border-1">
        <ul>
          <li v-for="a in getAllAccount" :key="a.id">
            <p class="label">
              {{ a.label }}
            </p>
            <p class="value">
              {{ a.accountNumber }}
            </p>
          </li>
        </ul>
      </div>

      <div class="listWithActionsv2">
        <p class="header-2">
          {{ $t('profile_action_header') }}
        </p>
        <ul>
          <li @click="helpCenter()">
            <div class="details">
              <p class="label">
                {{ $t('profile_helpcenter') }}
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
          <li @click="openHelp()">
            <div class="details">
              <p class="label">
                {{ $t('profile_getintouch') }}
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
          <li v-if="false">
            <div class="details">
              <p class="label">
                {{ $t('profile_limits') }}
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
          <li @click="openDisclosures()">
            <div class="details">
              <p class="label">
                {{ $t('profile_disclosures') }}
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
          <li @click="addOwner">
            <div class="details">
              <p class="label">
                Add Business Owner
              </p>
            </div>
            <span class="icon-Arrow-small-right icon" />
          </li>
        </ul>
      </div>

      <div class="addressWithInfo">
        <div class="addressWithInfo__header flex-between-center">
          <p class="header-2">
            {{ $t('location') }}
          </p>
          <span @click="editAddress();">
            {{ $t('Edit') }}
          </span>
        </div>

        <div class="addressWithInfo__body">
          <div class="map">
            <GmapMap
              v-if="lat && lng"
              :center="{ lat, lng }"
              :zoom="7"
              map-type-id="terrain"
              style="width: 100%; height: 200px">
              <GmapMarker
                :position="{ lat, lng }"
                :clickable="true"
                :draggable="false" />
            </GmapMap>
          </div>
          <ul>
            <li>
              <span class="label">{{ $t('address') }}</span>
              <span class="value">{{ address }}</span>
            </li>
            <li>
              <span class="label">{{ $t('contact_country_title') }}</span>
              <span class="value">
                {{ getSelectedBusiness.address.country }}
              </span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import { getBrandConfig } from '@/utils/env';

export default {
  data() {
    return {
      lat: null,
      lng: null
    };
  },
  computed: {
    ...mapGetters('kyb', ['getSelectedBusiness']),
    ...mapGetters('account', ['getAllAccount']),
    google: gmapApi,
    address() {
      const user = this.getSelectedBusiness;
      const address = user.address;
      if (address.line2 && address.line2.length > 0) {
        return (
          address.line1 +
          ', ' +
          address.line2 +
          ', ' +
          address.city +
          ', ' +
          address.state
        );
      }
      return address.line1 + ', ' + address.city + ', ' + address.state;
    }
  },
  created() {
    this.getAccountsList();
    this.getLatLong();
  },
  methods: {
    ...mapActions('account', ['listAllAccounts']),
    getLatLong() {
      this.$gmapApiPromiseLazy().then(() => {
        if (this.google) {
          const geocoder = new this.google.maps.Geocoder();
          const address = this.getSelectedBusiness.address;
          const addressString =
            address.line1 +
            ',' +
            address.line2 +
            ',' +
            address.city +
            ',' +
            address.country;

          geocoder.geocode({ address: addressString }, (results, status) => {
            if (status == this.google.maps.GeocoderStatus.OK) {
              const latitude = results[0].geometry.location.lat();
              const longitude = results[0].geometry.location.lng();
              this.lat = latitude;
              this.lng = longitude;
            }
          });
        }
      });
    },
    addOwner(){
      this.$router.push({name: 'OwnersList'});
    },
    helpCenter() {
      const url = getBrandConfig('helpCenter', 'https://intercom.help/solid-2cf7e39d14f4/en/');
      var win = window.open(
        url,
        '_blank'
      );
      win.focus();
    },
    getAccountsList() {
      const loader = this.showLoader();
      this.listAllAccounts(this.getSelectedBusiness.id)
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
        });
    },
    openDisclosures() {
      const url = getBrandConfig('disclosures', 'https://intercom.help/solid-2cf7e39d14f4/en/collections/2921684-legal-disclosures-terms-policies')
      var win = window.open(
        url,
        '_blank'
      );
      win.focus();
    },
    editAddress() {
      this.drawerPush('EditBusinessAddress');
    },
  }
};
</script>
<style lang="scss">
.businessDetail {
  .loginHeader {
    .submit {
      margin-left: auto;
    }
  }
}
</style>
